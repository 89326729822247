
import styled from "styled-components";
import lateral from '../../../assets/images/home/page3/goey_MVP_MAI22.png';
import goey from '../../../assets/images/home/page4/goeyFrota_CG160.png';
import mobile from '../../../assets/images/home/page4/goeyFrotaMobile.png';
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  min-height: 360px;
  border-radius: 15px;
  background-color: #3341F5;
  position: relative;
  @media screen and (max-width:800px){
    grid-template-columns: repeat(1, 1fr);
    min-height: 100vh;
    border-radius: 0px;
    padding:30px;
    
    /* padding:20px; */
  }

`;

export const DivImg = styled.div`
  background-image: url(${goey});
  height: 100%;
  background-repeat: no-repeat;
  margin-left: 20%;
  @media screen and (max-width:800px){
    background-size: 250px auto;
    height: 160px;
    margin-left: 0;
    background-image: url(${mobile});
    justify-content: center;
    background-size: contain;
  }
`
export const DivText = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
  h5{
    font-family: 'Bebas Neue';
    font-size:28px;
    text-align: left;
    color:#FFF
  }
  p{
    font-family: 'Bebas Neue';
    font-size:58px;
    text-align: left;
    color:#FFF;
    line-height: 1;
  }
  button{
    height: 65px;
    background-color: #fff;
    color:#000;
    border-radius: 15px;
    border:none;
    font-size:18px;
    font-family: 'avenir_nextbold';
    width: 450px;
  }

  @media screen and (max-width:800px){
    button{
      width: 300px !important;
    }
  }

`
