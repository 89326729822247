import React, { useEffect, useState } from 'react';
import { Button, Carousel, Container } from 'react-bootstrap';
import './styles.css'
import image1 from './../../../assets/images/carousel/goey_HomeImg_JUL22.png';
import image2 from './../../../assets/images/carousel/goey_HondaStart_MAI22.png';
import image3 from './../../../assets/images/carousel/goey_HondaFan_MAI22.png';
import image4 from './../../../assets/images/carousel/goey_HondaCargo_JUN22.png';
import image6 from './../../../assets/images/carousel/goey_yamahaCrosser150_MAI22.png';
import image7 from './../../../assets/images/carousel/goey_Shineray125X2_MAI22.png';

import imageMobile1 from './../../../assets/images/carousel/mobile/goey_HomeCGFanM_JUNI22.png';
import imageMobile2 from './../../../assets/images/carousel/mobile/goey_HomeCGCargoM_JUNI22.png';
import imageMobile3 from './../../../assets/images/carousel/mobile/goey_HomeShinerayM_JUNI22.png';
import imageMobile4 from './../../../assets/images/carousel/mobile/goey_HomeCGStartM_JUNI22.png';
import imageMobile5 from './../../../assets/images/carousel/mobile/goey_HomeImg_JUL22.png';
import android from '../../../assets/images/android.png';
import ios from '../../../assets/images/ios.png';
const CarouselContainer = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const img = [
    {image: image1,alt:'goey'},
    {image: image2,alt:'goey'},
    {image: image3,alt:'goey'},
    {image: image4,alt:'goey'},
    {image: image6,alt:'goey'},
    {image: image7,alt:'goey'}
  ]
  const imgMobile = [
    {image: imageMobile5,alt:'goey'},
    {image: imageMobile1,alt:'goey'},
    {image: imageMobile2,alt:'goey'},
    {image: imageMobile3,alt:'goey'},
    {image: imageMobile4,alt:'goey'},
  ]
  const open = (num:number) => {
    
    console.log(num)
    if(num == 1) {
      window.open('https://api.whatsapp.com/send?phone=5511913682869','_blank');
    }else{

    }
  }
  return (
    
    Number(windowSize.innerWidth) > Number(800) ? 
    <Carousel fade={true} pause={false} indicators={false} className="carou" interval={5000} >
      {
        img.map( (value:any,index :any) => {
          return(
            <Carousel.Item interval={2000} key={index}>
              <img
                className="image"
                src={value.image}
                alt={value.alt}
            />
              <Carousel.Caption style={{marginTop:'20px'}}>
                <div className='group_btn'>
                  <Button variant="dark" style={{marginRight:'10px',width:'315px',maxHeight:'35px !important',backgroundColor:'#000000'}} onClick={ (e) =>  window.open('https://api.whatsapp.com/send?phone=5511913682869','_blank')}>CHAMA NO WHATSAPP</Button>
                  <Button variant="primary" style={{width:'315px',backgroundColor:'#3341F5'}}
                  onClick={ () => {document.getElementById('planos')?.scrollIntoView()}}
                  >NOSSOS PLANOS DE LOCAÇÃO</Button>
                  <p style={{fontFamily:'avenir_nextregular',fontSize:'12px',marginBottom:'0px !important'}}>BAIXE NOSSO APP GRATUITAMENTE</p>
                  <div>
                      <img src={android} alt="Baixe na loja Play Store" style={{maxWidth:'128px',marginRight:'20px',cursor:'pointer'}}  onClick={ () => {window.open('https://play.google.com/store/apps/details?id=br.com.goey','_blank')} }/>
                      <img src={ios} alt="Baixe na loja Apple Store" style={{maxWidth:'128px',cursor:'pointer'}} onClick={ () => {window.open('https://apps.apple.com/br/app/goey/id1543756423?l=en','_blank')}} />
                  </div>
                </div>
               
              </Carousel.Caption>
            </Carousel.Item>
          )
        } )
      }
    </Carousel>
    :
    <Carousel fade={true} pause={false} indicators={false} interval={5000}>
      {
         imgMobile.map( (value:any,index :any) => {
          return(
            <Carousel.Item interval={2000} key={index}>
            <img
              className="d-block"
              src={value.image}
              alt={value.alt}
              style={{marginTop:'-60px'}}
          />
            <Carousel.Caption>
              <div className='btn_group'>
                <Button variant="dark" style={{width:'455px',backgroundColor:'#000'}} onClick={ (e) =>  window.open('https://api.whatsapp.com/send?phone=5511913682869','_blank')}>CHAMA NO WHATSAPP</Button>
                <Button variant="primary" style={{width:'455px',backgroundColor:'#3341F5'}}>NOSSOS PLANOS DE LOCAÇÃO</Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          )
        } )
      }
  </Carousel>

    
    
  )
}
function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}
export default CarouselContainer;