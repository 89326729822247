//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';

import { Col, Container, Row } from 'react-bootstrap';
import cfc from '../../../assets/images/cfc/goey_CNHMoto-V2_JUN22@2x.png'
const useStyles = makeStyles((theme: Theme) => ({

  copy: {
    fontFamily: 'avenir_nextregular',
    fontSize: '18px',
    color: '#fff',
    marginTop: '30px',
    '@media (max-width:780px)': {
      fontSize: '14px',
      marginTop: '0px',
    }
  },
  container: {
    paddingTop: '10%',
    '@media (max-width:780px)': {
      display:'none'
    }
  },
  container2:{
    paddingTop: '10%',
    padding:'12px',
    display:'none',
    '@media (max-width:780px)': {
      display:'flex',
      flexDirection:'column'
    }
  },
  p: {
    fontFamily: 'Bebas Neue',
    fontSize: '30px',
    textAlign: 'center',
    width: '100%'
  },

  blueBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'flex-end',
    height: '65px',
    width: '100%',
    borderRadius: '15px',
    backgroundColor: '#3341F5',
    color: '#FFF',
    fontWeight: 'bold',
    fontFamily: 'avenir_nextbold',
    border: 'none !important',
    fontSize:'18px',
    '@media (max-width:780px)':{
      display:'flex',
      alignSelf:'center',
      justifyContent:'center',
      alignItems:'center',
      width: '100% !important',
      height: '35px',
      fontSize:'14px'
    }
   
  },
  title: {
    fontSize: '70px',
    fontFamily: 'Bebas Neue',
    lineHeight:'78px',
    color:'#000',
    '@media (max-width:780px)': {
      display:'none'
    }
  },
  title2: {
    fontFamily: 'Bebas Neue',
    '@media (max-width:780px)': {
      fontSize: '29px',
    }
  },
  info: {
    fontFamily: 'avenir_nextregular',
    fontSize: '18px',
    color: '#FFFFFF',
    '@media (max-width:780px)': {
      fontSize: '14px',
      padding: '20px 30px',
      textAlign: 'left',
      paddingBottom: '0px'
    }
  },
  box: {
    backgroundColor: '#707070', minHeight: '170px', display: 'flex', alignItems: 'center', marginTop: '100px',
    '@media (max-width:780px)': {
      marginTop: '30px'
    }
  },
  span:{
    fontSize:'26px',
    lineHeight:'36px',
    fontFamily:'avenir_nextregular',
    color:"#000000"
  },
  span2:{
    fontSize:'14px',
    lineHeight:'24px',
    fontFamily:'avenir_nextregular',
    color:"#000000 !important"
  }
}));

const Page2: React.FC = () => {
  const classes = useStyles();

  return (

    <Container fluid style={{ backgroundColor: '#EBEBEB', minHeight: '100vh', marginTop: '-80px' }}>


      <Row className={classes.container}>
        <Col xs={12} md={{ span: 4, offset: 2 }}>
          <h2 className={classes.title}>INDIQUE AMIGOS E GANHE DESCONTOS EXCLUSIVOS</h2>
          <p className={classes.span}>#1 - Preencha o formulário com as informações da pessoa que você quer indicar para tirar a CNH.</p>
          <p className={classes.span}>#2 - Assim que a pessoa iniciar o processo de habilitação você já ganha a sua diária na Goey.</p>
          <p className={classes.span}>#3 - Se a sua indicação alugar uma moto na Goey, você ganha 2 diárias. Basta ele informar seu número de contrato na retirada do veículo.</p>
          <button className={classes.blueBtn} onClick={ (e) =>  window.open('https://docs.google.com/forms/d/e/1FAIpQLSeFodt2pXbyawGEFkHVZbPlam7jaRfn2CbC4cZyfAXsDLEWHA/viewform','_blank')}>QUERO INDICAR UM AMIGO AGORA</button>
        </Col>
        <Col xs={12} md={{ span: 4 }}>
          <img src={cfc} style={{ maxWidth: '90%' }} />
        </Col>
      </Row>

      <Row className={classes.container2}>

        <Col xs={12} md={{ span: 4 }}>
          <h2 className={classes.title2}>INDIQUE AMIGOS E GANHE DESCONTOS EXCLUSIVOS</h2>
        </Col>
        <Col xs={12} md={{ span: 4 }}>
          <img src={cfc} style={{ maxWidth: '100%' }} />
        </Col>

        <Col xs={12} md={{ span: 4, offset: 2 }} style={{marginTop:'20px'}}>
          
          <p className={classes.span2}>#1 - Preencha o formulário com as informações da pessoa que você quer indicar para tirar a CNH.</p>
          <p className={classes.span2}>#2 - Assim que a pessoa iniciar o processo de habilitação você já ganha a sua diária na Goey.</p>
          <p className={classes.span2}>#3 - Se a sua indicação alugar uma moto na Goey, você ganha 2 diárias. Basta ele informar seu número de contrato na retirada do veículo.</p>
          <button className={classes.blueBtn} onClick={ (e) =>  window.open('https://docs.google.com/forms/d/e/1FAIpQLSeFodt2pXbyawGEFkHVZbPlam7jaRfn2CbC4cZyfAXsDLEWHA/viewform','_blank')}>QUERO INDICAR UM AMIGO AGORA</button>
        </Col>
      
      </Row>

      <Row className={classes.box} >
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <p className={classes.info}>Lembrando que a indicação é de apenas um amigo por CPF/Contrato não sendo acumulativa. Os valores podem variar e sofrer alterações a qualquer momento a critério da Goey. Condições válidas também para o o ”amigo” indicado. Os descontos podem variar de acordo com o tipo de serviço exemplo: Primeira CNH, renovação ou até mesmo reabilitação.</p>
        </Col>
      </Row>

    </Container>


  );
};

export default Page2;

