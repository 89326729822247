//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';

import { Col, Container, Row } from 'react-bootstrap';
import moto from '../../assets/images/goeyFrota_CG160@2x.png'
const useStyles = makeStyles((theme: Theme) => ({

  copy: {
    fontFamily: 'avenir_nextregular',
    fontSize: '18px',
    color: '#fff',
    marginTop: '30px',
    '@media (max-width:780px)': {
      fontSize: '14px',
      marginTop: '0px',
    }
  },
  container: {
    paddingTop: '10%',
    '@media (max-width:780px)': {
      // display:'flex',
      // flexDirection:'column-reverse !important',
      padding:'28px 28px 0px 28px'
    }
  },

  p: {
    fontFamily: 'avenir_nextregular',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#000',
    letterSpacing: '-0.45px',
    '@media (max-width:780px)': {
      fontSize: '14px',
      letterSpacing: '-0.35px',
      lineHeight: '24px',
      color: "#000 !important"
    }
  },
  first: {
    fontFamily: 'avenir_nextregular',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#000',
    letterSpacing: '-0.45px',
    '@media (max-width:780px)': {
      fontSize: '28px',
      fontFamily: 'Bebas Neue',
      letterSpacing: '-0.7px',
      lineHeight: '58px',
      color: "#000 !important"
    }
  },
  second: {
    fontFamily: 'Bebas Neue',
    fontSize: '58px',
    lineHeight: '58px',
    color: '#000',
    letterSpacing: '-1.45px',
    width: '70%',
    '@media (max-width:780px)': {
      fontSize: '20px',
      letterSpacing: '-0.5px',
      lineHeight: '29px',
      color: "#000 !important"
    }
  },
  title: {
    fontFamily: 'avenir_nextbold',
    fontSize: '26px',
    lineHeight: '36px',
    color: '#000',
    letterSpacing: '-0.65px',
    paddingTop: '30%',
    '@media (max-width:780px)': {
      paddingTop: '10px',
      fontSize:'18px',
      lineHeight: '26px',
      letterSpacing: '-0.45px',
    }
  },
  blackBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'flex-end',
    height: '65px',
    width: '60%',
    borderRadius: '15px',
    backgroundColor: '#000',
    color: '#FFF',
    fontWeight: 'bold',
    fontFamily: 'avenir_nextbold',
    border: 'none !important',
    fontSize: '18px',
    marginTop: '20px',
    marginBottom: '80px',
    '@media (max-width:780px)': {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100% !important',
      height: '35px',
      fontSize: '14px',
      marginTop: '20px',
      marginBottom: '30px',
    }

  },

  img: {
    maxWidth: '100%',
    '@media (max-width:780px)': {
      maxWidth: '100%',
      marginTop: '10px',
    }
  },


}));
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const Page1: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (

    <Container fluid style={{ backgroundColor: '#EBEBEB', minHeight: '100vh', marginTop: '-80px' }}>

      {
        Number(windowSize.innerWidth) > Number(800) ?
          <Row className={classes.container}>
            <Col xs={12} md={{ span: 5, offset: 2 }} style={{ textAlign: 'left' }}>
              <h5 className={classes.first}>SOLUÇÃO EMPRESARIAL (FROTAS)</h5>
              <h1 className={classes.second}>MAIS ECONOMIA, SEGURANÇA & AGILIDADE PARA SUA EMPRESA.</h1>
              <img src={moto} className={classes.img} />
              <button className={classes.blackBtn} onClick={ () => {window.open("https://api.whatsapp.com/send?phone=5511913682869","_blank")}}>FALE COM UM CONSULTOR</button>
            </Col>
            <Col xs={12} md={{ span: 4 }} >
              <p className={classes.title}>Mais agilidade para sua empresa.</p>
              <p className={classes.p}>—— Frota terceirizada é sinônimo de economia e eficiência para sua empresa. Com ela você tem capital disponível para investimento em áreas estratégicas. </p>
              <p className={classes.p}>—— Produto flexível e ideal para necessidades sazonais, pontuais ou sem prazo de devolução específico. </p>
              <p className={classes.p}>—— Veículos monitorados, redução de custo e maior produtividade </p>
              <p className={classes.p}>—— Custo zero com a depreciação do veículo, previsibilidade dos custos de frota, frota sempre disponível e produtiva.</p>

            </Col>
          </Row>
          :
          <Row className={classes.container}>
            <Col xs={12} md={{ span: 5, offset: 2 }} style={{ textAlign: 'left',marginTop:'100px' }}>
              <h5 className={classes.first}>SOLUÇÃO EMPRESARIAL (FROTAS)</h5>
              <img src={moto} className={classes.img} />
            </Col>
            <Col xs={12} md={{ span: 4 }} >
              <p className={classes.title}>Mais agilidade para sua empresa.</p>
              <p className={classes.p} style={{marginTop:'10px'}}>—— Frota terceirizada é sinônimo de economia e eficiência para sua empresa. Com ela você tem capital disponível para investimento em áreas estratégicas. </p>
              <p className={classes.p}>—— Produto flexível e ideal para necessidades sazonais, pontuais ou sem prazo de devolução específico. </p>
              <p className={classes.p}>—— Veículos monitorados, redução de custo e maior produtividade </p>
              <p className={classes.p}>—— Custo zero com a depreciação do veículo, previsibilidade dos custos de frota, frota sempre disponível e produtiva.</p>
              <button className={classes.blackBtn} onClick={ () => {window.open("https://api.whatsapp.com/send?phone=5511913682869","_blank")}}>FALE COM UM CONSULTOR</button>
            </Col>
          </Row>
      }




    </Container>


  );
};

export default Page1;

