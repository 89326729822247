/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
// import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import menu from '../../assets/images/Menu.svg';
import login from '../../assets/images/Login.svg';
import goey from '../../assets/images/goey_LogoPreto@2x.png';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  // MobileIcon,
  //   NavMenu,
  NavHam,
  NavLogin,
} from '../Navbar/NavbarElements';

//MENU
const Navbar = ({ toggle }) => {
  //FUNCAO SCROLL
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
            <img className="LogoPrincipal" src={goey} />
            </NavLogo>
            <NavHam >
              <img className="LogoPrincipal" src={menu} onClick={toggle} />
              {/* <NavLogin >
                <img className="ImgLogin" src={login} />
              </NavLogin> */}
            </NavHam>
            
            {/* <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>    */}

            {/* <NavMenu> */}
            {/* <NavItem>
                        <NavLinks to='about' 
                        //smoooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        >About</NavLinks>
                    </NavItem>

                    <NavItem>
                        <NavLinks to='block' 
                         //smoooth={true}
                         duration={500}
                         spy={true}
                         exact='true'
                         offset={-80} 
                        >The Future</NavLinks>
                    </NavItem>

                    <NavItem>
                        <NavLinks to='dollar'
                         //smoooth={true}
                         duration={500}
                         spy={true}
                         exact='true'
                         offset={-80} 
                        > Vehicles</NavLinks>
                    </NavItem>

                    <NavItem>
                        <NavLinks to='discover'
                         //smoooth={true}
                         duration={500}
                         spy={true}
                         exact='true'
                         offset={-80} 
                        >Services/Products</NavLinks>
                    </NavItem> */}
            {/* </NavMenu> */}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;

//yarn add styled-components
//yarn add react-icons
//yarn add react-scroll
