//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';

import { Col, Container, Row } from 'react-bootstrap';
import insta from '../../assets/images/medias/insta@2x.png'
import facebook from '../../assets/images/medias/facebook@2x.png'
import linkedin from '../../assets/images/medias/linkedin@2x.png'
import youtube from '../../assets/images/medias/youtube@2x.png'
import android from '../../assets/images/android.png';
import ios from '../../assets/images/ios.png';
const useStyles = makeStyles((theme: Theme) => ({
  mobileHidden:{
    marginTop:'30px',
    '@media (max-width:780px)': {
      display:'none !important'
    },
  },
  blockImage:{
    maxWidth:'32px',
    height:'32px',
    textAlign:'center',
    alignSelf:'center',
    '@media (max-width:780px)':{
     
     
      
    }
  },
  block:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    height:'71px !important'
  },
  facebook:{
    textAlign:'center',
    alignSelf:'center',
    height:'31px',
    width:'16px',
    '@media (max-width:780px)':{
      height:'28px',
      width:'14px'
    }
  },
  linkedin:{
    textAlign:'center',
    alignSelf:'center',
    height:'31px',
    width:'31px',
    '@media (max-width:780px)':{
      height:'28px',
      width:'28px'
    }
  },
  insta:{
    textAlign:'center',
    alignSelf:'center',
    height:'31px',
    width:'32px',
    '@media (max-width:780px)':{
      height:'28px',
      width:'28px'
    }
  },
  youtube:{
    textAlign:'center',
    alignSelf:'center',
    height:'31px',
    width:'45px',
    '@media (max-width:780px)':{
      height:'28px',
      width:'39px'
    }
  },
  p:{
    color:'#FF9F07',
    fontFamily:'Bebas Neue',
    fontSize:'28px',
    marginTop:'130px',
    '@media (max-width:780px)':{
      marginTop:'78px',
    }
  },
  blockText:{
    fontFamily:'Bebas Neue',
    fontSize:'65px',
    color:'#FFF',
    lineHeight:'80px',
    '@media (max-width:780px)':{
      fontSize:'28px',
      lineHeight:'29px',
    }
  },
  secondP:{
    fontFamily:'Bebas Neue',
    fontSize:'26px',
    color:'#FFF',
    '@media (max-width:780px)':{
      fontSize:'19px',
    }
  },
  pfooter:{
    fontFamily:'avenir_nextbold',
    fontSize:'22px',
    color:'#FFF',
    marginTop:'30px',
    '@media (max-width:780px)':{
      margin:'0px'
    }
  },
  subFooter:{
    fontFamily:'avenir_nextregular',
    fontSize:'18px',
    color:'#FFF',
  },
  linha:{
    '@media (max-width:780px)':{
      marginTop:'40px'
    }
  },
  copy:{
    fontFamily:'avenir_nextregular',
    fontSize:'18px',
    color:'#fff',
    marginTop:'30px',
    '@media (max-width:780px)':{
      fontSize:'14px',
      marginTop:'0px',
    }
  },
  fontp:{
    fontFamily:'avenir_nextregular',
    fontSize:'14px',
    lineHeight:'32px',
    letterSpacing:'-0.35px',
    color:'#FFF'
  }
}));

const Page5: React.FC = () => {
  const classes = useStyles();

  return (

    <Container fluid style={{ backgroundColor: '#313131', minHeight: '100vh' }}>
      <Row >
        <Col xs={12} md={{ span: 2, offset: 2 }}>
          <p className={classes.p}>UM LUGAR PARA TODOS.</p>
        </Col>
      </Row>
      <Row >
        <Col xs={12} md={{ span: 6, offset: 2 }} lg={{span:5,offset:2}}>
          <p className={classes.blockText}>“GOEY, Quando a mobilidade urbana contribui para o desenvolvimento da mobilidade social.”</p>
        </Col>
      </Row>
      <Row style={{marginTop:'80px'}}>
        <Col xs={12} md={{ span: 2, offset: 2 }}>
          <p className={classes.secondP}>NAS REDES SOCIAIS</p>
          <hr style={{height:'2px',backgroundColor:'#FFF'}}/>
        </Col>
      </Row>
      <Row style={{marginTop:'30px'}}>
        <Col xs={12} md={{ span: 3, offset: 2 }} lg={{ span: 2, offset: 2 }}  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <a href="https://www.linkedin.com/company/goeymobilidade/" target="_blank" className={classes.block}>
              <img src={linkedin} className={classes.linkedin}/>
              <p className={classes.fontp}>Linkedin</p>
            </a>
          </div>
          <div>
          <a href="https://www.facebook.com/goeyja" className={classes.block} target="_blank">
              <img src={facebook} className={classes.facebook}/>
              <p className={classes.fontp}>Facebook</p>
            </a>
          </div>
          <div>
          <a href="https://www.instagram.com/goeyja/" className={classes.block} target="_blank">
              <img src={insta} className={classes.insta}/>
              <p className={classes.fontp}>Instagram</p>
            </a>
          </div>
          <div>
          <a href="https://www.youtube.com/channel/UCp3OBIxObs0pZkVjj8maW7Q" className={classes.block} target="_blank">
              <img src={youtube} className={classes.youtube}/>
              <p className={classes.fontp}>Youtube</p>
            </a>
          </div>
          
        </Col>
      </Row>

      <Row style={{marginTop:'40px'}}>
        <Col xs={12} md={{ span: 2, offset: 2 }}>
        <p className={classes.secondP}>NOSSAS UNIDADES DE ATENDIMENTO</p>
          <hr style={{height:'2px',backgroundColor:'#FFF'}}/>
        </Col>
      </Row>

      <Row >
        <Col xs={12} md={{ span: 2, offset: 2 }}>
          <p className={classes.pfooter}>CAMPINAS/SP</p>
          <p className={classes.subFooter}>R. Lotário Novaes, 71 Taquaral.</p>
        </Col>

        <Col xs={12} md={{ span: 2, }}>
          <p className={classes.pfooter}>SÃO PAULO/SP</p>
          <p className={classes.subFooter}>R. Cândido do Vale, 307 Tatuapé.</p>
        </Col>

        <Col xs={12} md={{ span: 2, }}>
          <p className={classes.pfooter}>RIO DE JANEIRO/RJ</p>
          <p className={classes.subFooter}>Av. Paulo de Frontin,126 Praça da Bandeira - Centro.</p>
        </Col>
        
        <Col xs={12} md={{ span: 2, }} className={classes.mobileHidden}>
          
          <p style={{fontSize:'12px',color:'#FFF'}}>BAIXE NOSSO APP GRATUITAMENTE</p>
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <img src={android} style={{width:'138px'}}/>
            <img src={ios} style={{width:'138px'}}/>
          </div>
        </Col>
        <Col xs={12} md={{ span: 8, offset: 2 }} className={classes.linha}>
          <hr style={{height:'2px',backgroundColor:'#FFF' }}/>
        </Col>
        <Col xs={12} md={{ span: 3, offset: 2 }} >
          <p className={classes.copy}>Copyright © 2022 Goey Mobilidade Inteligente. Todos os direitos reservados.</p>
        </Col>
        <Col xs={12} md={{ span: 3, offset: 2 }} className={classes.mobileHidden} style={{display:'flex',justifyContent:'flex-end'}}>
          <p style={{fontSize:'18px',fontFamily:'avenir_nextregular',color:'#FFF'}}>Política de Privacidade | Termos de Contrato |</p>
        </Col>
        
      </Row>
    </Container>


  );
};

export default Page5;

