import React from 'react';
import { Link } from 'react-router-dom';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from '../Sidebar/SidebarElements';
import './styles.css';
import close from '../../assets/images/x.svg';
const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <img src={close} className="close"/>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          
          <SidebarLink>
            <p className='OrangeMenu'>MENU</p>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/sobre">_SOBRE NÓS</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/empresa">_LOCAÇÃO EMPRESAS</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/cfc">_MOTO ESCOLA GOEY</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/recomeco">_PROJETO RECOMEÇO</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/moto-eletrica">_MOTO ELÉTRICA</Link>
          </SidebarLink>

       
          
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
