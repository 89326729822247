//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';

import { Col, Container, Row } from 'react-bootstrap';
import cfc from '../../../assets/images/cfc/moto_CFC@2x.png'
const useStyles = makeStyles((theme: Theme) => ({
  
  copy:{
    fontFamily:'avenir_nextregular',
    fontSize:'18px',
    color:'#fff',
    marginTop:'30px',
    '@media (max-width:780px)':{
      fontSize:'14px',
      marginTop:'0px',
    }
  },
  container:{
    paddingTop:'130px',
    
  },
  p:{
    fontFamily:'Bebas Neue',
    fontSize:'30px',
    textAlign:'center',
    width:'100%'
  },
  imgMobile:{
    '@media (max-width:780px)':{
      padding:'30px'
    }
  },
  second:{
    fontFamily:'Bebas Neue',
    fontSize:'70px',
    textAlign:'center',
    width:'100%',
    marginTop:'10px',
    '@media (max-width:780px)':{
      fontSize:'45px',
    }
  },
  third:{
    fontFamily:'avenir_nextregular',
    fontSize:'26px',
    lineHeight:'36px',
    textAlign:'center',
    letterSpacing:'-0.65px !important',
    '@media (max-width:780px)':{
      fontSize:'14px',
      lineHeight:'19px'
    }
  },
  blueBtn:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    alignContent:'flex-end',
    height: '65px',
    width: '100%',
    borderRadius:'15px',
    backgroundColor:'#3341F5',
    color:'#FFF',
    fontWeight:'bold',
    fontFamily:'avenir_nextbold',
    border:'none !important',
    '@media (max-width:780px)':{
      display:'flex',
      alignSelf:'center',
      justifyContent:'center',
      alignItems:'center',
      width: '80% !important',
      height: '35px'
    }
  }
}));

const Page1: React.FC = () => {
  const classes = useStyles();

  return (

    <Container fluid style={{ backgroundColor: '#EBEBEB', minHeight: '100vh',marginTop:'-80px' }}>
      <Row className={classes.container}>
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <p className={classes.p}><span style={{color:'#3341F5'}}>#EMBREVE</span> UMA NOVA MOTO ESCOLA</p>
        </Col>
      </Row>
      <Row >
        <Col xs={12} md={{ span: 4, offset: 4 }}>
         <img src={cfc} style={{maxWidth:'100%'}} />
        </Col>
      </Row> 
      <Row >
        <Col xs={12} md={{ span: 4, offset: 4 }}>
         <p className={classes.second}>TIRE SUA <span style={{color:'#3341F5'}}>CNH</span> NA GOEY</p>
        </Col>
      </Row> 
      <Row >
        <Col xs={12} md={{ span: 4, offset: 4 }}>
        <p className={classes.third}>Habilitação categoria <span style={{color:'#3341F5',fontFamily:'avenir_nextbold'}}>A</span> é na Moto Escola Goey</p>
        </Col>
      </Row>  
      <Row >
        <Col xs={12} md={{ span: 2, offset: 5 }} style={{display:'flex',justifyContent:'center'}}>
        <button className={classes.blueBtn} onClick={ (e) =>  window.open('https://api.whatsapp.com/send?phone=5511913682869','_blank')}>FALAR COM UM CONSULTOR</button>
        </Col>
      </Row>
    </Container>


  );
};

export default Page1;

