//@ts-nocheck
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import first from '../../../assets/images/home/page2/first.png';
import banner from '../../../assets/images/home/page2/goey_bannerSmall_MAI22.png';
import moto from '../../../assets/images/home/page2/goey_MotoEletrica_MAI22.png';
import mobile from '../../../assets/images/home/page2/mobile.png';
import './styles.css';
import motoescola from '../../../assets/images/home/motoescola_img.png';
import motoescolamobile from '../../../assets/images/home/motoescolamobile@2x.png';
import { Link } from 'react-router-dom';
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100%',
    minHeight: '100vh',
    backgroundColor: '#FFF',
    marginTop: '80px !important',
    padding: '15px !important',
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'center',
    '@media (max-width:780px)': {
      margin: '0px !important',
      padding: '0px !important'
    },
  },


  image: {
    width: '100% !important',
    alignContent: 'center',
    margin: 'auto',
    // padding:'15px',
    '@media (max-width:780px)': {
      width: '100%',
      height: '100vh',
      margin: '0px',
      padding: '0px'
    },
  },
  secondBlock: {
    marginTop: '30px !important',
    '@media (max-width:780px)': {
      marginTop: '0px !important',
      paddingTop: '0px !important'
    },
  },
  hidden: {
    '@media (max-width:780px)': {
      display: 'none !important'
    },
  },
  desk: {
    backgroundImage: `url(${motoescola})`,
    height: '521px',
    borderRadius: '15px',
    backgroundRepeat: 'no-repeat',
    width: '100% !important',
    alignContent: 'center',
    margin: 'auto',
    '@media (max-width:780px)': {
      display: 'none !important'
    },
  },
  mob: {
    backgroundImage: `url(${motoescolamobile})`,
    height: '100vh !important',
    backgroundSize: 'cover',
    '@media (max-width:780px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    },
  }
}));

const Page1: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Container className={classes.Container}>
      <Grid container>
        <Grid item xs={12} md={12} className={classes.firstBlock}>
          {
            Number(windowSize.innerWidth) > Number(800) ?
              <div className={classes.desk}>
                <p style={{ fontFamily: 'Bebas Neue', fontSize: '28px', color: '#FFF', paddingLeft: '10%', paddingTop: '100px' }}>CENTRO DE FORMAÇÃO DE CONDUTOR</p>
                <p style={{ fontFamily: 'Bebas Neue', fontSize: '58px', color: '#FFF', lineHeight: '70px', paddingLeft: '10%' }}>AINDA Não possui habilitação?<br /> Tire sua cnh com a goey
                  <Link to="cfc"><button style={{ backgroundColor: '#FFF', justifyContent: 'center', display: 'flex', height: '65px', width: '450px', border: 'none', borderRadius: '15px' }}>
                    <p style={{ fontFamily: 'avenir_nextbold', fontSize: '14px', color: '#000', marginBottom: '40px' }}>SAIBA MAIS</p>
                  </button>
                  </Link>
                </p>
              </div>
              :
              <div className={classes.mob}>
                <p style={{ fontFamily: 'Bebas Neue', fontSize: '64px', color: '#FFF', lineHeight: '60px', padding: '40px', textAlign: 'left', marginTop: 'auto',paddingTop:'30px' }}>AINDA Não possui habilitação? Tire sua cnh com a goey
                  <Link to="cfc">
                    
                    <button style={{ backgroundColor: '#000', justifyContent: 'center', display: 'flex', height: '45px', width: '100%', border: 'none', borderRadius: '15px' }}>
                      <p style={{ fontFamily: 'avenir_nextbold', fontSize: '14px', color: '#FFF',alignSelf:'center',paddingTop:'20px' }}>SAIBA MAIS</p>
                    </button>
                  </Link>
                </p>
                {/* <img src={motoescolamobile} className={classes.image} /> */}

              </div>


          }

        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.secondBlock}>
        <Grid item xs={12} md={6} className={classes.hidden}>
          <Link to="recomeco">
            <img src={banner} className={classes.image} />
          </Link>

        </Grid>
        <Grid item xs={12} md={6}>
          <Link to="moto-eletrica">
            {
              Number(windowSize.innerWidth) > Number(800) ?
                <img src={moto} className={classes.image} /> :
                <img src={mobile} className={classes.image} />
            }
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page1;

