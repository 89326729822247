//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import goey from '../../../assets/images/home/page5/goeyja_MotoGrupo.png';
import { FormControl } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
// import { Grid, DivText, DivForm } from './styles';
// import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100%',
    // minHeight: '100vh',
    backgroundColor: '#FFF',
    marginTop: '50px !important',
    // padding:'15px !important',
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'center',
    '@media (max-width:780px)': {
      // paddingTop: '20px !important',
      margin: '0px !important',
      padding: '0px !important'
    },
  },
  imagem: {
    width: '615px',
    height: '100%',
    objectFit: 'contain !important',
    marginTop: '-50px',
    '@media (max-width:780px)': {
      maxWidth: '300px',
      marginBottom: '30px'
    },
  },

  input: {
    width: '100%',
    borderRadius: '15px',
    backgroundColor: '#FFF',
    border: 'none',
    height: '60px',
    paddingLeft: '20px',
    '&:active, &:focus': {
      border: 'none !important',
      outline: 'none',
      justifyContent: 'center'
    },
    marginTop: '15px',
    '@media (max-width:780px)': {
      // paddingTop:'60px'
      height: '35px',
    },
  },
  hidden: {
    display: 'none !important',
    '@media (max-width:780px)': {
      display: 'none !important',
    },
  },
  pm: {
    display: 'none',
    '@media (max-width:780px)': {
      display: 'inline !important',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      fontFamily: 'Bebas Neue',
      fontSize: '30px',
      lineHeight: '44px',
      textAlign: 'left',
    },
  },
  p1: {
    fontFamily: 'Bebas Neue',
    fontSize: '39px',
    lineHeight: '44px',
    textAlign: 'left',
    '@media (max-width:780px)': {
      padding: '20px',
      fontSize: '30px',
      display: 'none !important'
    },
  },
  mobile: {
    '@media (max-width:780px)': {
      marginTop: '60px !important'
    },
  },
  button: {
    fontFamily: 'avenir_nextbold',
    fontWeight: 'bold',
    fontSize: '26px !important',
    height: '65px',
    borderRadius: '15px',
    backgroundColor: '#3341F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    cursor: 'pointer',
    '@media (max-width:780px)': {
      fontSize: '18px !important'
    },
  }
}));

const Page5: React.FC = () => {
  const classes = useStyles();
  const recaptchaRef = React.createRef();
  const [cap, setCap] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  function onChange(value) {
    if (value == null) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    setCap(value)
  }
  useEffect( () => {
    new RDStationForms('goey_siteleads_jul22-2bd133388f2ee76c5ea9', 'UA-138039007-2').createForm();
  },[])
  return (

    <Container fluid style={{ paddingTop: '5%', backgroundColor: '#FFF', minHeight: '100vh' }}>
      <Row style={{ paddingTop: '40px', marginTop: '40px' }}>
        <Col xs={12} md={{ span: 4, offset: 2 }}>
          <p className={classes.p1}>Com a Goey <span style={{ color: '#3341F5' }}>você aluga</span> sua moto de<br /> <span style={{ color: '#3341F5' }}>trabalho</span> e do <span style={{ color: '#3341F5' }}>dia a dia</span>. SIMPLES, RÁPIDO<br /> & PARA TODOS.</p>
          <p className={classes.pm}>Com a Goey <span style={{ color: '#3341F5' }}>você aluga</span> sua moto<br /> de <span style={{ color: '#3341F5' }}>trabalho</span> e do <span style={{ color: '#3341F5' }}>dia a dia</span>.<br /> SIMPLES, RÁPIDO & PARA TODOS.</p>
          <img src={goey} className={classes.imagem} />
        </Col>
        <Col xs={12}  md={{ span: 4, offset: 1 }}>
          <Row>
            <Col md={12}>
            <div role="main" id="goey_siteleads_jul22-2bd133388f2ee76c5ea9">
            </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md={6} className={classes.mobile}>
              <input className={classes.input} placeholder="NOME COMPLETO" />
            </Col>
            <Col md={6}>
              <input className={classes.input} placeholder="CPF" />
            </Col>
          </Row>
         
          
          <Row>
            <Col md={6}>
              <input className={classes.input} placeholder="EMAIL@EMAIL" />
            </Col>
            <Col md={6}>
              <input className={classes.input} placeholder="DDD XXXXXXXX" />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <input className={classes.input} placeholder="UF" />
            </Col>
            <Col md={8}>
              <input className={classes.input} placeholder="LOCAL RETIRADA" />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <input className={classes.input} placeholder="TIPO VEÍCULO" />
            </Col>
            <Col md={6}>
              <input className={classes.input} placeholder="MODELO" />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <input className={classes.input} placeholder="DATA INÍCIO" />
            </Col>
            <Col md={4}>
              <input className={classes.input} placeholder="DATA FIM" />
            </Col>
            <Col md={4}>
              <input className={classes.input} placeholder="R$ 00,00" />
            </Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col md={8} xs={12}>
              <div className={classes.button}>
                Enviar proposta de locação
              </div>
            </Col>
          </Row> */}
          {/* <Row>
            <Col xs={12} md={12} style={{ fontSize: '11px', fontFamily: 'avenir_nextregular', color: '#707070', textAlign: 'left', marginTop: '10px' }}>
              Ao continuar, eu concordo com os Termos de Uso da GOEY e confirmo que li a Política de Privacidade. Eu também concordo que a GOEY e seus representantes podem entrar em contato comigo por e-mail, telefone ou SMS (inclusive por meios automatizados) no endereço de e-mail ou número que eu forneci, inclusive para fins de marketing.
            </Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col xs={12} md={8} style={{ marginTop: '20px' }}>
              <ReCAPTCHA
                sitekey="6Ldzb98ZAAAAAPELzYUDPC2L1bRcijMrLNX8MoJk"
                onChange={onChange}
                ref={recaptchaRef}
              />
            </Col>
          </Row> */}

        </Col>
      </Row>

    </Container>


  );
};

export default Page5;

