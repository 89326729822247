//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';

import { Col, Container, Row } from 'react-bootstrap';
// import moto from '../../assets/images/goeyFrota_CG160@2x.png'
const useStyles = makeStyles((theme: Theme) => ({

  container: {
    paddingTop: '10%',
    '@media (max-width:780px)': {
      // display:'flex',
      // flexDirection:'column-reverse !important',
      padding: '28px 28px 0px 28px'
    }
  },

  p: {
    fontFamily: 'avenir_nextregular',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#000',
    letterSpacing: '-0.45px',
    '@media (max-width:780px)': {
      fontSize: '14px',
      letterSpacing: '-0.35px',
      lineHeight: '24px',
      color: "#000 !important"
    }
  },

  img: {
    maxWidth: '100%',
    '@media (max-width:780px)': {
      maxWidth: '100%',
      marginTop: '10px',
    }
  },
  orangeBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#FF9F07',
    height: '400px',
    borderRadius: '15px',
    padding: '139px 79px',
    width: '98%',
    '@media (max-width:1336px)': {
      padding: '120px 30px',
    },
    '@media (max-width:830px)': {
      marginTop:'100px',
      padding: '20px 40px',
      fontSize:'29px',
      lineHeight:'29px',
      height:'auto',
      letterSpacing:'-0.72px'
    }
  },
  firstBoxOrange: {
    fontFamily: 'Bebas Neue',
    fontSize: '40px',
    lineHeight: '40px',
    spacingLetter: '-1px',
    color: '#000',
    '@media (max-width:830px)': {
      fontSize:'29px',
      lineHeight:'29px',
      height:'auto',
      letterSpacing:'-0.72px'
    }
  },
  blueBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#3341F5',
    height: '400px',
    borderRadius: '15px',
    padding: '139px 45px',
    '@media (max-width:1336px)': {
      padding: '120px 45px',
      height:'auto'
    },
    '@media (max-width:830px)': {
      marginTop:'20px',
      padding: '20px 40px',
      fontSize:'29px',
      lineHeight:'29px',
      minHeight:'auto',
      letterSpacing:'-0.72px'
    }
  },
  firstBoxBlue: {
    fontFamily: 'Bebas Neue',
    fontSize: '40px',
    lineHeight: '40px',
    spacingLetter: '-1px',
    color: '#FFF',
    '@media (max-width:830px)': {
      fontSize:'29px',
      lineHeight:'29px',
      height:'auto',
      letterSpacing:'-0.72px'
    }
  },
  blackBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: 'center',
    paddingLeft: '15%',
    backgroundColor: '#000',
    height: '400px',
    borderRadius: '15px',
    marginTop: '23px',
    marginBottom: '30px',
    '@media (max-width:840px)': {
      padding: '19px 12px 10px 12px',
      fontSize:'29px',
      lineHeight:'29px',
      height:'auto'
    }
  },
  firstBoxBlack: {
    fontFamily: 'Bebas Neue',
    fontSize: '40px',
    lineHeight: '40px',
    spacingLetter: '-1px',
    color: '#FFF',
    '@media (max-width:830px)': {
      fontSize:'29px',
      lineHeight:'29px',
      height:'auto'
    }
  },
  secondBoxBlack: {
    fontFamily: 'avenir_nextregular',
    fontSize: '26px',
    lineHeight: '36px',
    spacingLetter: '-0.65px',
    color: '#FFF'
  },
  whiteBox: {
    // maxWidth:'615px',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    backgroundColor: '#FFF',
    minHeight: '820px',
    width: '80%',
    marginRight: '100px',
    borderRadius: '15px',
    padding: '130px 55px 0px 55px',
    '@media (max-width:1336px)': {
      width: '100%',
      marginTop: '20px',
      padding:'20px',
      minHeight:'auto !important'
    }
    // width: '98%'
  },
  firstBoxWhite: {
    fontFamily: 'avenir_nextregular',
    fontSize: '26px',
    lineHeight: '36px',
    spacingLetter: '-0.65px',
    color: '#000',
    '@media (max-width:830px)': {
      fontSize: '14px !important',
      lineHeight: '24px',
      spacingLetter: '-0.35px',
    }
  },
  secondBoxWhite: {
    fontFamily: 'avenir_nextregular',
    fontSize: '26px',
    lineHeight: '36px',
    spacingLetter: '-0.65px',
    color: '#000',
    marginTop: '20px'
  },


}));
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const Page1: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (

    Number(windowSize.innerWidth) > Number(800) ?
      <Container fluid style={{ backgroundColor: '#EBEBEB', minHeight: '100vh', marginTop: '-80px' }}>

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: '7%', marginLeft: '50px' }}>
          <div style={{ width: '49%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginRight: '2%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%', marginRight: '1%' }}>
                  <div className={classes.orangeBox}>
                    <h5 className={classes.firstBoxOrange}>Inovamos e operamos para tornar o mundo um lugar melhor.</h5>
                  </div>
                </div>
                <div style={{ width: '50%' }}>
                  <div className={classes.blueBox}>
                    <h5 className={classes.firstBoxBlue}>Nossos valores fazem parte de tudo construído aqui, tudo para você.</h5>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ width: '100%' }}>
                  <div className={classes.blackBox}>
                    <h5 className={classes.firstBoxBlack}>“Acreditamos que o PENSAMENTO verde E a<br /> tecnologia podem transformar as cidades.”</h5><br />
                    <p className={classes.secondBoxBlack}>- Marcus Cominato, CEO</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: '49%' }}>
            <div className={classes.whiteBox}>
              <hr style={{ height: '2px', color: '#707070', width: '65px' }} /><br />
              <h5 className={classes.firstBoxWhite}>Nossa missão é conectar mobilidade urbana a mobilidade social e meio ambiente para uma vida mais justa e sustentável.</h5>
              <h5 className={classes.secondBoxWhite}>Operando de forma inovadora serviços de
                locação de moto para tornar mais fácil a vida
                de quem utiliza a moto como principal meio
                de transporte. Nosso foco está em criar
                maneiras de andar “verde” com o
                crescimento dos veículos elétricos, pois
                sabemos que nosso impacto vai além da
                nossa tecnologia..</h5>
              <hr style={{ height: '2px', color: '#707070', width: '100%' }} /><br />
            </div>
          </div>
        </div>
      </Container>
      :
      <Container fluid style={{ backgroundColor: '#EBEBEB', minHeight: '100vh', marginTop: '-80px' }}>
        <Row>
          <Col xs={12} >
            <div className={classes.orangeBox}>
              <h5 className={classes.firstBoxOrange}>Inovamos e operamos para tornar o mundo um lugar melhor.</h5>
            </div>
          </Col>

          <Col xs={12} md={5} >
            <div className={classes.blueBox}>
              <h5 className={classes.firstBoxBlue}>Nossos valores fazem parte de tudo construído aqui, tudo para você.</h5>
            </div>
          </Col>
          <Col xs={12} >
            <div className={classes.whiteBox}>
              <hr style={{ height: '5pt', color: '#707070', width: '65px' }} /><br />
              <p className={classes.firstBoxWhite}>Nossa missão é conectar mobilidade urbana<br /> a mobilidade social e meio ambiente para<br /> uma vida mais justa e sustentável.</p>
              <h5 className={classes.firstBoxWhite}>Operando de forma inovadora serviços de<br />
                locação de moto para tornar mais fácil a vida<br />
                de quem utiliza a moto como principal meio<br />
                de transporte. Nosso foco está em criar<br />
                maneiras de andar “verde” com o<br />
                crescimento dos veículos elétricos, pois<br />
                sabemos que nosso impacto vai além da<br />
                nossa tecnologia..</h5>
              <hr style={{ height: '2px', color: '#707070', width: '100%' }} /><br />
            </div>
          </Col>

          <Col xs={{ span: 12 }}>
            <div className={classes.blackBox}>
              <h5 className={classes.firstBoxBlack}>“Acreditamos que o PENSAMENTO verde E a tecnologia podem transformar as cidades.”</h5>
              <p className={classes.secondBoxBlack}>- Marcus Cominato, CEO</p>
            </div>
          </Col>
        </Row>
      </Container>




  );
};

export default Page1;

