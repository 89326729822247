//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';

import { Col, Container, Row } from 'react-bootstrap';
import man from '../../assets/images/recomeco/man@2x.png'
const useStyles = makeStyles((theme: Theme) => ({

  copy: {
    fontFamily: 'avenir_nextregular',
    fontSize: '18px',
    color: '#fff',
    marginTop: '30px',
    '@media (max-width:780px)': {
      fontSize: '14px',
      marginTop: '0px',
    }
  },
  container: {
    paddingTop: '10%',
    '@media (max-width:780px)': {
      display:'flex',
      flexDirection:'column-reverse !important',
      padding:'36px 36px 0px 36px'
    }
  },
  
  p: {
    fontFamily: 'Bebas Neue',
    fontSize: '40px',
    lineHeight:'70px',
    color:'#000',
    letterSpacing:'-1px',
    '@media (max-width:780px)': {
      fontSize:'20px',
      letterSpacing:'-0.5px',
      lineHeight:'29px',
      color:"#000 !important"
    }
  },
  h5:{
    fontFamily:'avenir_nextregular',
    fontSize:'18px',
    lineHeight:'25px',
    color:'#3341F5',
    marginTop:'95px',
    letterSpacing:'-0.45px',
    '@media (max-width:780px)': {
      fontSize:'14px',
      letterSpacing:'-0.35px',
      lineHeight:'24px',
      color:'#000 !important'
    }
  },
  h1:{
    fontFamily: 'Bebas Neue',
    fontSize: '70px',
    color:'#000',
    marginTop:'10px',
    letterSpacing:'-1.75px',
    '@media (max-width:780px)': {
      fontSize:'40px',
      letterSpacing:'-1px',
      lineHeight:'29px',
      marginTop:'20px',
    }
  },
  text:{
    fontFamily:'avenir_nextregular',
    fontSize:'26px',
    lineHeight:'36px',
    color:'#000',
    marginTop:'10px',
    letterSpacing:'-0.65px',
    '@media (max-width:780px)': {
      fontSize:'14px',
      letterSpacing:'-0.35px',
      lineHeight:'24px',
      color:'#000 !important',
      fontFamily:'avenir_nextregular !important',
    }
  },
  footer:{
    fontFamily:'avenir_nextregular',
    fontSize:'18px',
    lineHeight:'26px',
    color:'#707070',
    marginTop:'20px',
    '@media (max-width:780px)': {
      fontSize:'12px',
      letterSpacing:'-0.30px',
      lineHeight:'20px',
      color:'#000 !important',
      fontFamily:'avenir_nextregular !important',
    }
  },
  blueBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'flex-end',
    height: '65px',
    width: '100%',
    borderRadius: '15px',
    backgroundColor: '#3341F5',
    color: '#FFF',
    fontWeight: 'bold',
    fontFamily: 'avenir_nextbold',
    border: 'none !important',
    fontSize: '18px',
    marginTop:'40px',
    '@media (max-width:780px)': {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100% !important',
      height: '35px',
      fontSize: '14px',
      marginTop:'20px'
    }

  },
  blue:{
    color:'#3341F5',
    '@media (max-width:780px)': {
      color:'#000'
    }
  },
  bold:{
    fontFamily:'avenir_nextbold',
    '@media (max-width:780px)': {
      fontFamily:'avenir_nextregular'
    }
  }
  
}));

const Page1: React.FC = () => {
  const classes = useStyles();

  return (

    <Container fluid style={{ backgroundColor: '#EBEBEB', minHeight: '100vh', marginTop: '-80px' }}>


      <Row className={classes.container}>
        <Col xs={12} md={{ span: 4, offset: 2 }} style={{textAlign:'center'}}>
          <img src={man} style={{ maxWidth: '90%' }} />
        </Col>
        <Col xs={12} md={{ span: 4 }} style={{paddingTop:'4%'}}>
          <h5 className={classes.h5}>PROJETO RECOMEÇO</h5>
          <h1 className={classes.h1}>#MOBILIDADE<span style={{color:'#3341F5'}}>SOCIAL</span></h1>
          <p className={classes.p}><span className={classes.blue}>90/dias</span> PARA VOcê criar uma nova história</p>
          <p className={classes.text}>Nenhuma pessoa é capaz de sonhar tão alto como você, seja o autor da sua própria história com a Goey. O <span className={classes.bold}>Projeto Recomeço</span> é pra você que nunca pensou em desistir. Mais do que um projeto social, uma chance de mudar, criar e crescer.</p>
          <button className={classes.blueBtn} onClick={ () => {window.open('https://docs.google.com/forms/d/e/1FAIpQLSeFodt2pXbyawGEFkHVZbPlam7jaRfn2CbC4cZyfAXsDLEWHA/viewform','_blank')}}>QUERO ME INSCREVER AGORA</button>
          <p className={classes.footer}>Projeto com vagas limitadas, processo seletivo, duração de 90 dias. Sujeito a disponibilidade de veículos, o projeto é destinado para a cidade de São Paulo/SP, podendo ser cancelado a qualquer momento.</p>
        </Col>
      </Row>


    </Container>


  );
};

export default Page1;

