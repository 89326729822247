import React from 'react';
// @ts-nocheck
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import Home from './pages/Home';
import ScrollToTop from './components/ScrollToTop';
import Cfc from './pages/Cfc';
import Recomeco from './pages/Recomeco'
import Moto from './pages/Moto'
import Empresa from './pages/Empresa';
import Sobre from './pages/Sobre';
const Rotas = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/cfc" element={<Cfc />} />
        <Route path="/recomeco" element={<Recomeco />} />
        <Route path="/moto-eletrica" element={<Moto />} />
        <Route path="/empresa" element={<Empresa />} />
        <Route path="/sobre" element={<Sobre />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

export default Rotas;
