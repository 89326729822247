import React, { useState } from 'react';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Carousel from '../../components/Home/Carousel'
import Page2 from '../../components/Home/Page2'
import Page3 from '../../components/Home/Page3'
import Page4 from '../../components/Home/Page4'
import Page5 from '../../components/Home/Page5'
const Home: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Carousel />
      <Page2 />
      <Page3 />
      <Page4 />
      <Page5 />
      <Footer/>
    </>
  );
};

export default Home;
