//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';

import { Col, Container, Row } from 'react-bootstrap';
import boy from '../../../assets/images/img_boy@2x.png'

const useStyles = makeStyles((theme: Theme) => ({

  container: {
    paddingTop: '10%',
    '@media (max-width:780px)': {
      // display:'flex',
      // flexDirection:'column-reverse !important',
      padding: '28px 28px 0px 28px'
    }
  },

  p: {
    fontFamily: 'avenir_nextregular',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#000',
    letterSpacing: '-0.45px',
    '@media (max-width:780px)': {
      fontSize: '14px',
      letterSpacing: '-0.35px',
      lineHeight: '24px',
      color: "#000 !important"
    }
  },

  img: {
    maxWidth: '100%',
    backgroundImage:`url(${boy})`,
    backgroundSize:'cover',
    height:'810px',
    '@media (max-width:780px)': {
      borderRadius:'15px',
      maxWidth: '100%',
      marginTop: '80px',
      height: '85%',
      width:'100%'
    }
  },
 
  blackBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '20px',
    paddingLeft: '10%',
    paddingRight: '10%',
    backgroundColor: '#000',
    height: '400px',
    borderRadius: '15px',
    '@media (max-width:840px)': {
      padding: '19px 12px 10px 12px',
      fontSize: '29px',
      lineHeight: '29px',
      height: 'auto',
      
    }
  },
  whiteBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '10px',
    paddingLeft: '10%',
    paddingRight: '10%',
    backgroundColor: '#FFF',
    height: '400px',
    borderRadius: '15px',
    '@media (max-width:840px)': {
      padding: '19px 12px 10px 12px',
      fontSize: '29px',
      lineHeight: '29px',
      height: 'auto',
      marginTop:'35px'
      
    }
  },
  firstWhiteBox:{
    fontFamily:'Bebas Neue',
    fontSize:'40px',
    lineHeight:'40px',
    letterSpacing:'-1px',
    color:'#000',
    '@media (max-width:840px)': {
      padding: '19px 12px 10px 12px',
      fontSize: '29px',
      lineHeight: '29px',
      height: 'auto',
     letterSpacing:'-0.72' 
    }
    
  },
  firstBlackBox:{
    fontFamily:'Bebas Neue',
    fontSize:'40px',
    lineHeight:'40px',
    letterSpacing:'-1px',
    color:'#FFF',
    '@media (max-width:840px)': {
      padding: '19px 12px 10px 12px',
      fontSize: '29px',
      lineHeight: '29px',
      height: 'auto',
     letterSpacing:'-0.72' 
    }
  },
  imgP:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    paddingTop:'65%',
    fontFamily:'Bebas Neue',
    fontSize:'40px',
    lineHeight:'40px',
    letterSpacing:'-1px',
    color:'#FFF',
 
    '@media (max-width:840px)': {
      height:'50%',
      
      fontSize: '29px',
      lineHeight: '29px',
      // height: 'auto',
     letterSpacing:'-0.72' 
    }
  }


}));
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const Page1: React.FC = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (

    Number(windowSize.innerWidth) > Number(800) ?
      <Container fluid style={{ backgroundColor: '#EBEBEB', minHeight: '100vh', marginTop: '-80px' }}>

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: '7%', marginLeft: '50px' }}>
          <div style={{ width: '49%' }} className={classes.img}>
            {/* <img src={boy} className={classes.img} /> */}
            <p className={classes.imgP}>/ Foco no futuro.<br/> Mais mobilidade, menos CO².</p>
          </div>

          <div style={{ width: '49%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginLeft: '2%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div>
                <div style={{ width: '80%' }}>
                  <div className={classes.whiteBox}>
                    <h5 className={classes.firstWhiteBox}>Explore uma cultura colaborativa de inclusão, crescimento e originalidade, apoiada por recursos que fazem a diferença na vida.</h5>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ width: '80%' }}>
                  <div className={classes.blackBox}>
                    <h5 className={classes.firstBlackBox}>Nosso legado é impactar nossos clientes, colegas e o planeta. Uma trilha de integração de pessoas e oportunidades.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Container>
      :
      <Container fluid style={{ backgroundColor: '#EBEBEB', minHeight: '100vh', marginTop: '-80px' }}>
        <Row>
          <Col xs={12} >
          <div style={{ width: '100%' }} className={classes.img}>
            {/* <img src={boy} className={classes.img} /> */}
            <p className={classes.imgP}>/ Foco no futuro.<br/> Mais mobilidade, menos CO².</p>
          </div>
          </Col>

          <Col xs={12} md={5} >
          <div className={classes.whiteBox}>
                    <h5 className={classes.firstWhiteBox}>Explore uma cultura colaborativa de inclusão, crescimento e originalidade, apoiada por recursos que fazem a diferença na vida.</h5>
                  </div>
          </Col>
          <Col xs={12} >
          <div className={classes.blackBox}>
                    <h5 className={classes.firstBlackBox}>Nosso legado é impactar nossos clientes, colegas e o planeta. Uma trilha de integração de pessoas e oportunidades.</h5>
                  </div>
          </Col>

          
        </Row>
      </Container>




  );
};

export default Page1;

