//@ts-nocheck
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
// import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { minHeight } from '@mui/system';
import Box from '@material-ui/core/Box';
import { Grid, NewItem } from './styles'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100%',
    minHeight: '50vh',
    backgroundColor: '#FFF',
    marginTop: '50px !important',
    padding:'15px !important',
    display: 'flex !important',
    flexDirection:'column !important',
    // justifyContent:'center',
    '@media (max-width:780px)': {
      margin:'0px !important',
      padding:'0px !important'
    },
  },
 

  image: {
    width: '100% !important',
    alignContent:'center',
    margin:'auto',
    // padding:'15px',
    '@media (max-width:780px)': {
      width: '100%',
      height:'100vh',
      margin:'0px',
      padding:'0px'
    },
  },
  secondBlock:{
    marginTop:'30px !important'
  },
  hidden:{
    '@media (max-width:780px)': {
     display:'none !important'
    },
  },
  firstBlock:{
    borderRadius:'15px',
  },
  blackBtn:{
    justifyContent:'flex-end',
    alignContent:'flex-end',
    height: '65px',
    width: '100%',
    borderRadius:'15px',
    backgroundColor:'#000',
    color:'#FFF',
    fontWeight:'bold',
    fontFamily:'avenir_nextbold',
    marginBottom:'10px'
    
  },  yellowBtn:{
    justifyContent:'flex-end',
    alignContent:'flex-end',
    height: '65px',
    width: '100%',
    borderRadius:'15px',
    backgroundColor:'#FF9F07',
    color:'#000',
    fontWeight:'bold',
    fontFamily:'avenir_nextbold',
    
  },  blueBtn:{
    justifyContent:'flex-end',
    alignContent:'flex-end',
    height: '65px',
    width: '100%',
    borderRadius:'15px',
    backgroundColor:'#3341F5',
    color:'#FFF',
    fontWeight:'bold',
    fontFamily:'avenir_nextbold',
    
  },
  firstText:{
    marginTop:'40px',
    fontFamily:'Bebas Neue',
    textAlign:'center',
    fontSize:'58px',
    lineHeight:'50px',
    marginBottom:'40px',
    color:'#000',
    '@media (max-width:780px)': {
      fontSize:'29px',
      marginBottom:'14px',
      lineHeight:'30px',
     },
  },
  normal:{
    fontFamily:'avenir_nextregular',
    fontSize:'18px',
    textAlign:'left',
    lineHeight:'20px',
    marginTop:'10px',
    minHeight:'150px',
    '@media (max-width:780px)': {
      marginBottom:'30px',
      fontSize:'14px',
      marginTop:'14px',
      minHeight:'110px',
     },
  },
  bold:{
    fontWeight:'bold',
    fontFamily:'avenir_nextbold',
    fontSize:'22px',
    '@media (max-width:780px)': {
      fontSize:'14px',
      marginBottom:'10px !important'
     },
  },
  block1:{
    backgroundColor:'#F2F2F2',
    // minHeight:'600px'
    borderBottomRightRadius:'15px',
    borderBottomLeftRadius:'15px',
  },
  block2:{
    backgroundColor:'#FFF',
    // minHeight:'600px'
    borderBottomRightRadius:'15px',
    borderBottomLeftRadius:'15px'
  },
  block3:{
    backgroundColor:'#000',
    // minHeight:'600px',
    color:'#FFF',
    borderBottomRightRadius:'15px',
    borderBottomLeftRadius:'15px'

  },
  firstMargin:{

    '@media (max-width:780px)': {
     '&>:nth-child(1)':{
        marginTop:'20px'
     }

     },
  }
 
}));

const Page3: React.FC = () => {
  const classes = useStyles();

  
  return (
    <Container className={classes.Container} id="planos" >

      
        <Grid item xs={12} md={3} className={classes.firstMargin}>
          <NewItem className={classes.firstBlock} color="1">
            <div style={{padding:'30px'}}>
              <h1 className={classes.firstText}>Plano <br/><span style={{color:'#3341f5'}}>#Correria</span>Total</h1>
              <p className={classes.normal}>Oferecemos um contrato mensal, com pagamentos semanais via boleto: serão 4 boletos com vencimento semanal, pagos somente após 7 dias da retirada da moto.</p>
              <strong className={classes.bold}>Ideal para você que:</strong>
              <p className={classes.normal}>. Ideal para descobrir a moto ideal para você <br/>. Está precisando de uma moto temporária para trabalhar e fazer aquele corre. <br/>. Vai locar por um período máximo de 30 dias</p>
            </div>
            <button className={classes.blackBtn} onClick={ () => {window.open('https://api.whatsapp.com/send?phone=5511913682869&text=quero+saber+sobre+o+plano+correriatotal',"_blank")}}>SAIBA MAIS SOBRE ESSE PLANO</button>
          </NewItem>
            
          <NewItem className={classes.firstBlock} color="2">
            <div style={{padding:'30px'}}>
            <h1 className={classes.firstText}>Plano <br/><span style={{color:'#3341f5'}}>#CABENO</span>BOLSO</h1>
            <p className={classes.normal}>O desconto é oferecido pelo pagamento em dia do primeiro mês de aluguel, sendo válido a partir do segundo mês de aluguel com 5% de desconto, e no terceiro mês, 10% de desconto!</p>
            <strong className={classes.bold}>Ideal para você que:</strong>
            <p className={classes.normal}>. Quer alugar uma moto por mais de 30 dias. <br/>. Pretende alugar durante uma média de 3 a 5 meses.</p>
            
            </div>
            <button className={classes.blueBtn} onClick={ () => {window.open("https://api.whatsapp.com/send?phone=5511913682869","_blank")}}>CHAMA NO WHATSAPP</button>
            </NewItem>

            <NewItem className={classes.firstBlock} color="1">
            <div style={{padding:'30px'}}>
            <h1 className={classes.firstText}>Plano <br/><span style={{color:'#3341f5'}}>#MINHA</span>MOTO</h1>
            <p className={classes.normal}>Neste Plano, após 48 meses sem nenhum atraso no pagamento, você se torna o proprietário da moto.</p>
            <strong className={classes.bold}>Ideal para você que:</strong>
            <p className={classes.normal}>
            . Quer ter uma moto zero para rodar. <br/>. Pretende alugar por mais de um ano. <br/>. Não tem período definido para alugar a moto, mas sabe que será por um período longo.
            </p>
            </div>
            <button className={classes.blackBtn} onClick={ () => {window.open("https://api.whatsapp.com/send?phone=5511913682869","_blank")}}>FALE COM UM CONSULTOR</button>
          </NewItem>

          <NewItem className={classes.firstBlock} color="3">
            <div style={{padding:'30px'}}>
            <h2 className={classes.firstText}>Plano <br/><span style={{color:'#FF9F07'}}>#ASSINATURA</span></h2>
            <p className={classes.normal}>Com nosso plano de assinatura você pode ter uma moto pelo período de 3/6 ou 12 meses. Com limite de 1.200km mês, você tem total controle sobre o gasto.</p>
            <strong className={classes.bold}>Ideal para você que:</strong>
            <p className={classes.normal}>
            . Roda até 1.200km por mês. <br/>. Precisa de uma meio de transporte rápido. <br/>. Não quer se preocupar com manutenção e quer sempre andar de moto zero.
            </p>
          </div>
          <button className={classes.yellowBtn} onClick={ () => {window.open('https://api.whatsapp.com/send?phone=5511913682869&text=quero+saber+sobre+o+plano+assinatura',"_blank")}}>QUERO SABER MAIS</button>
          </NewItem>
        </Grid>


      
    </Container>
  );
};

export default Page3;

