
import styled from "styled-components";
import lateral from '../../../assets/images/home/page3/goey_MVP_MAI22.png';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  min-height: 630px;
  gap:20px;
  position: relative;
  @media screen and (max-width:800px){
    grid-template-columns: repeat(1, 1fr);
    
  }

  

`;

export const NewItem = styled.div`
    background-color: ${props => props.color == '1' ? '#F2F2F2' : props.color == '2' ? '#FFF' : "#000" };
    border: ${props => props.color == '2' ? '1px solid #3449EC' : 'none' };
    background-image:${props => props.color == '2' ? `url(${lateral})` : 'none' };
    background-position: top right -10px;
    background-repeat: no-repeat;
    border-bottom: none !important;
    margin-bottom: auto;
    color: ${props => props.color == '3' ? '#FFF' : "#000" };
    button{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      margin-top: auto;
      border: none !important;
      margin:0px !important
  }
  h2:nth-child(1){
    color:#FFF
  }

  @media screen and (max-width:800px){
   width: 90%;
   margin-left: 5%;
  
  }

`;