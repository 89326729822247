//@ts-nocheck
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
// import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import goey from '../../../assets/images/home/page4/goeyFrota_CG160.png';
import { Grid, DivImg, DivText } from './styles';
function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100%',
    minHeight: '30vh',
    backgroundColor: '#FFF',
    marginTop: '50px !important',
    padding:'15px !important',
    display: 'flex !important',
    flexDirection:'column !important',
    // justifyContent:'center',
    '@media (max-width:780px)': {
      paddingTop:'20px !important',
      margin:'0px !important',
      padding:'0px !important'
    },
  },
 
 
}));

const Page4: React.FC = () => {
  const classes = useStyles();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  
  return (
    <Container className={classes.Container} >
      {
         Number(windowSize.innerWidth) > Number(800) ? 
         <Grid>
            <DivImg/> 
            <DivText>
                <h5>SOLUÇÃO EMPRESARIAL (FROTAS)</h5>
                <p>MAIS ECONOMIA, SEGURANÇA &<br/> AGILIDADE PARA SUA EMPRESA.</p>
                <button onClick={ () => {window.open('https://api.whatsapp.com/send?phone=5511913682869','_blank')} }>FALE COM UM CONSULTOR</button>
            </DivText>
        </Grid>
        :
        <Grid>
            <DivText><h5 className={classes.hidden}>SOLUÇÃO EMPRESARIAL (FROTAS)</h5></DivText>
            <DivImg/> 
            <DivText>
                <h5 className={classes.hidden}>SOLUÇÃO EMPRESARIAL (FROTAS)</h5>
                <p>MAIS ECONOMIA, SEGURANÇA &<br/> AGILIDADE PARA SUA EMPRESA.</p>
                <button>FALE COM UM CONSULTOR</button>
            </DivText>
        </Grid>
      }
        

        
    </Container>
  );
};

export default Page4;

